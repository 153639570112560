
.slick-list {
    max-height: 400px;
  }

  .variant-header{
    display: flex;
    justify-content: space-between;
  }

  .variant-header h5{
    margin-top:auto;
    margin-bottom:auto;
  }
 
  .order-table table tbody tr td:first-child {
    width: auto;
}
.order-table table tbody tr td {
  padding: 12px;
  border-bottom: 0;
}