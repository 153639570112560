.add-product ul li{
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 0px;
}

.add-product ul li .box-input-file{
  width:100px;
  height:100px;
}

.add-product ul li .box-input-file .upload{
  width:100px;
  height:100px;
}

.add-product ul li .box-input-file .removeBtn{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: red;
  background: white;
  border-radius: 50px;
  padding: 0 0.25rem;
}