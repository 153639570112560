.authentication-box .row {
  justify-content: center;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.back-btn {
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}
@media only screen and (max-width: 575px) {
  .form-group {
    margin-bottom: 0.5rem !important;
  }
  .product-adding .add-product-form {
    margin-top: 0px;
  }
  .add-product-form .form-group .input-group .form-control {
    width: 80% !important;
  }
  .add-product ul li .box-input-file img {
    width: inherit !important;
    height: inherit !important;
  }
  .file-upload-product li {
    margin-bottom: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .page-wrapper .page-body-wrapper .page-sidebar {
    width: 100vw;
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open {
    width: 0px;
  }
  .toko-detail-card .card-body {
    padding: 15px 0;
  }
}

.shippingHistory {
  margin-top: 1.5rem;
}
.shippingHistory .shippingHistory-sub {
  display: flex;
  margin-bottom: 1.5rem;
}
.shippingHistory .shippingHistory-sub .date {
  font-size: 14px;
}
.shippingHistory .shippingHistory-sub .strips {
  position: relative;
  font-size: 10px;
}
.shippingHistory .shippingHistory-sub .strips:before {
  content: "";
  border-left: 1px solid black;
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% + 1.6rem);
}

.shippingHistory .shippingHistory-sub:last-child .strips::before {
  content: unset;
}

.shippingHistory .shippingHistory-sub .remarks {
  font-size: 13px;
}

.font-weight-bold {
  font-weight: bold;
}
.hide {
  display: none;
  visibility: hidden;
}
.whatsapp {
  display: flex;
  height: 75vh;
}
.whatsapp .whatsapp-contact {
  height: 100%;
  overflow-y: auto;
}
.whatsapp .whatsapp-contact .whatsapp-contact_content {
  border-bottom: 1px solid #80808040;
  padding: 1rem 0.5rem;
}

.whatsapp-contact::-webkit-scrollbar-track,
.whatsapp-chat_content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.whatsapp-contact::-webkit-scrollbar-thumb,
.whatsapp-chat_content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.whatsapp-contact::-webkit-scrollbar-thumb:hover,
.whatsapp-chat_content::-webkit-scrollbar-thumb:hover {
  background: #838383;
}
.whatsapp-contact::-webkit-scrollbar,
.whatsapp-chat_content::-webkit-scrollbar {
  width: 10px;
}
.whatsapp .whatsapp-contact .whatsapp-contact_content.selected {
  background-color: #dedbdb4d;
}
.whatsapp .whatsapp-contact .whatsapp-contact_content:hover {
  background-color: #dedbdb4d;
}
.whatsapp .whatsapp-contact .whatsapp-contact_content_1 {
  display: flex;
  justify-content: space-between;
}
.whatsapp .whatsapp-contact .whatsapp-contact_content_1 div:last-child {
  font-size: 10px;
}
.whatsapp .whatsapp-contact .whatsapp-contact_content_2 {
  position: relative;
  font-size: 12px;
}
.whatsapp .whatsapp-contact .whatsapp-contact_content_2 span {
  position: absolute;
  top: -4px;
  right: 0;
  background: #d82025;
  padding: 0rem 0.4rem;
  border-radius: 15px;
  color: white;
  font-size: 14px;
}

.whatsapp .whatsapp-chat {
  height: 100%;
  overflow-y: auto;
  background-color: rgb(182 180 180);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-flow: column;
  position: relative;
}

.whatsapp .whatsapp-chat.mobile {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.whatsapp .whatsapp-chat .whatsapp-chat_content {
  height: 100%;
  /*bottom: 0;
  left: 0;
  right: 0;
  position: absolute;*/
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.whatsapp .whatsapp-chat .whatsapp-chat_content_message {
  background-color: white;
  padding: 0.5rem;
  width: fit-content;
  margin-top: 1rem;
  border-radius: 8px;
  white-space: pre-wrap;
}

.whatsapp .whatsapp-chat .whatsapp-chat_content_message.right {
  background-color: #dcf8c6;
  margin-left: auto;
  text-align: right;
}

.whatsapp .whatsapp-chat .whatsapp-chat_content_message.date-label {
  margin-left: auto;
  margin-right: auto;
  background-color: #d82025;
  font-size: 12px;
  color: white;
}

.whatsapp .whatsapp-chat .whatsapp-chat_content_message span {
  font-size: 8px;
  padding-left: 5px;
}

.whatsapp-chat_content_field {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0.75rem;
  position: absolute;
  bottom: 0;
  background-color: #ededed;
}
.whatsapp-chat_content_field input {
  height: 2rem;
  width: 95%;
}
.whatsapp-chat_content_field button {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0.75rem;
  border: none;
  background-color: unset;
}
.whatsapp-chat_content_field button i {
  font-size: 18px;
}

.whatsapp-chat_content_header {
  background-color: #ededed;
  width: 100%;
  padding: 0.75rem;
}
@media only screen and (max-width: 575px) {
  .whatsapp-chat_content_field input {
    width: 88%;
  }
}

@media only screen and (max-width: 1024px) {
  .whatsapp-chat_content_field input {
    width: 92%;
  }
}

.add-product-form .form-select {
  padding: 0;
}
.add-product-form .select__control {
  border: none;
}

.add-product-form .select__menu,
.add-product-form .select__menu .select__menu-list {
  z-index: 5;
}
.add-product-form .input-group > .form-select:not(:focus).is-invalid {
  z-index: unset;
}
.add-product-form .form-select.is-invalid:not([multiple]):not([size]) {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.add-product-form .invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.add-product-form .invalid-feedback.is-invalid {
  display: block;
}
.add-product-form .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

ul.list {
  background-color: #e4e4e4;
  padding: 2rem;
}

ul.list ol {
  display: list-item;
  list-style-position: inside;
  list-style-type: disc;
  padding: 0.5rem 0;
}

.ctooltip {
  background-color: #494848;
  color: rgb(255, 255, 255);
  padding: 1rem;
}

.ctooltip ul.list {
  background-color: unset;
  padding: 1rem;
}

ul.list ol {
  padding: 0;
}

.cetak-label {
  width: 105mm;
  height: 148mm;
  position: absolute;
  right: 10000px;
  //width:auto;
  //left:100px
  background-color: white;
  z-index: 99999;
}
.cetak-label .top {
  //width:100%;
  display: flex;
  justify-content: space-between;
  //padding: 1rem 1.5rem 0;
}
.cetak-label .top div {
  margin: auto 0;
  text-align: right;
  font-size: 12px;
}

.cetak-label .header {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid black;
  //margin: 1rem;
}

.cetak-label .header .header_ {
  display: flex;
  flex-wrap: nowrap;
}

.cetak-label .header .header_ img {
  //padding: 1rem 5.5rem;
  padding: 0.5rem;
  border-right: 1px solid black;
}

.cetak-label .header .header_ div {
  //font-size: 18px;
  margin: auto;
  width: auto;
}

.cetak-label .header .header_barcode {
  border-top: 1px solid black;
  align-items: center;
  text-align: center;
}

.cetak-label .header .header_barcode div {
  //font-size: 20px;
  //margin-top: -0.5rem;
    margin-top: -11px;
}

.cetak-label .body .body1,
.cetak-label .body .body2 {
  display: flex;
  flex-wrap: nowrap;
  //padding:1rem;
  justify-content: space-between;
  font-size: 10px;
  margin-top: 5px;
}

.cetak-label .body .body1 .value {
  font-weight: bold;
  //font-size:20px;
}

.cetak-label .body .body2 .value {
  //font-size:18px;
  padding-right: 5px;
}

.cetak-label .body .body3 {
  //margin:1rem;
  border: 1px solid black;
  //font-size:16px;
  text-align: center;
  background-color: #e3e3e3;
  margin: 5px 0;
  font-size: 12px;
}
.cetak-label .body .body3 .body3-1 {
  //padding: 1.5rem 6rem;
  border-bottom: 1px solid black;
  padding: 5px;
}
.cetak-label .body .body3 .body3-2 {
  //padding: 1.5rem 6rem;
  padding: 5px;
}

.cetak-label .body .body4 {
  //margin:1rem;
  border-top: 1px dashed black;
}

.cetak-label .body .body4 .body4-1 {
  //font-size: 18px;
  //margin:1rem 0;
  font-weight: bold;
}

.cetak-label .body .body4 .body4-2 {
  display: flex;
  flex-wrap: nowrap;
  //font-size: 16px;
  justify-content: space-between;
  //margin-bottom: 1rem;
  font-size: 12px;
  margin-top: 7px;
}
.cetak-label .body .body4 .body4-2 div {
  margin: auto 0;
  line-height: 1;
}
.cetak-label .body .body4 .body4-2 span {
  font-size: 10px;
}

.icons-widgets{
  padding-left:0;
}
.static-top-widget .icons-widgets .text-center{
  width: 35px;
  height: 35px;
}
.widget-cards {
  min-height:138px;
}

.custom-map-control-button{
  right: 0.75rem!important;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #ffffff;
  border: 1px solid;
  border: currentColor;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 24px;
  box-shadow: 0.75px 0.75px 0 1px #dbd8d8;
}

.error-body{
  border: 5px solid #dc3545;
}
.error-text{
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.h-0{
  height:0;
}

.filter-open{
  height:auto;
  //overflow-y: hidden;
}

.filter-close{
  height:50px;
  //overflow-y: hidden;
}

.selectFilter__menu{
  /*position: absolute!important;
  top:0!important;*/
}

.daterangepicker{
  top:calc(top-160px)!important;
}
.daterangepicker .first-column{
  top:160px!important;
}
.daterangepicker .second-column{
  top:210px!important;
}
.daterangepicker:before, .daterangepicker:after{
  display:none!important;
}

.error-template{
  height: 40vh;
  margin: auto 0;
  padding: 2rem;
  display: inline-grid;
  width: 100%;
  text-align: center;
}
.error-template .error-template-body{
  margin: auto 0;
  height: 10vh;
}